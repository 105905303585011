import axios from "axios";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import admin_logo from "../../src/images/footer-logo.png";
// import admin_logo from "../../src/images/logos/LOGO_WHITE.png";

const Footer: React.FC = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  const [emailValue, setEmailValue] = useState<any>("");
  const [footerPuckData, setFooterPuckData] = useState<any>([]);

  const refreshLink = () => {
    window.location.href = "/app/";
  };

  useEffect(() => {
    getFooterData();
  }, []);

  const handleContactEmail = (event: any) => {
    setEmailValue(event.target.value);
  };

  const subscribeNewsLetter = async () => {
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailValue) {
      return toast.warning("Please enter email first.");
    }

    if (emailValue) {
      const isValid = emailRegex.test(emailValue);
      if (!isValid) {
        return toast.warning("Please enter in proper email format.");
      }
    }
    axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        email: emailValue,
        action: "command",
        command: [
          {
            agent: "newsLetter",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setEmailValue("");
          return toast.success(result?.data?.message);
        } else if (result?.data?.statusCode === 400) {
          setEmailValue("");
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getVisitorsCount = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        action: "command",
        command: [
          {
            agent: "setVisitorCount",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.code === 1) {
          localStorage.setItem("visitor", "true");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let oldVisitor: any = localStorage.getItem("visitor");
  useEffect(() => {
    if (oldVisitor !== "true") {
      getVisitorsCount();
    }
  }, []);

  const getFooterData = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        name: "FOOTER",
        action: "command",
        menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
        command: [
          {
            agent: "pageByName",
            appName: "selfMaximized",
            folder: "pages",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setFooterPuckData(data);
        } else {
          setFooterPuckData([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const footerData: any = footerPuckData?.content?.find(
    (elem: any) => elem.type === "Footer"
  );
  return (
    <>
      <footer className="py-11">
        <div className="container-fluid left-right-space mt-7">
          {/* New Footer */}
          <div className="row">
            <div className="col-lg-3 pb-4 footer-first-container position-relative">
              <NavLink className="navbar-brand logo" to="/">
                <div className="footer-logo">
                  <img
                    src={
                      footerData?.props?.whiteLogo
                        ? footerData?.props?.whiteLogo
                        : admin_logo
                    }
                    alt="our logo"
                    className="img-fluid object-cover"
                    // style={{ width: "100px", height: "30px" }}
                    loading="lazy"
                    onClick={handleClick}
                  />
                </div>
              </NavLink>
              <p className="description text-uppercase pt-5">
                {footerData?.props?.footerText
                  ? footerData?.props?.footerText
                  : ` Medcity has been present in Europe since 1990, offering
                innovative solutions, specializing in medical services for
                treatment of medical infrastructure.`}
              </p>
            </div>
            <div className="col-lg-3 footer-second-container position-relative">
              <ul className="navbar-nav list-unstyled mb-0 ps-lg-10">
                <li className="mb-0 nav-item">
                  <NavLink className="nav-link " to="about">
                    {footerData?.props?.about
                      ? footerData?.props?.about
                      : "ABOUT"}
                  </NavLink>
                </li>
                <li className="mb-0 nav-item">
                  <NavLink className="nav-link" to="faq">
                    {footerData?.props?.faq ? footerData?.props?.faq : "FAQ"}
                  </NavLink>
                </li>
                <li className="mb-0 nav-item">
                  <NavLink
                    className="nav-link"
                    to={"specialistdetails"}
                    onClick={handleClick}
                  >
                    {footerData?.props?.becomeASpecialist
                      ? footerData?.props?.becomeASpecialist
                      : " BECOME A SPECIALIST"}
                  </NavLink>
                </li>
                <li className="mb-0 nav-item">
                  <NavLink className="nav-link" to="career">
                    {footerData?.props?.career
                      ? footerData?.props?.career
                      : "CAREERS"}
                  </NavLink>
                </li>
                <li className="mb-0 nav-item">
                  <NavLink to={"/contact-us"}>
                    <span
                      className="nav-link cursor_pointer"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {footerData?.props?.contactUs
                        ? footerData?.props?.contactUs
                        : "CONTACT US"}
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 footer-second-container position-relative">
              <ul className="navbar-nav list-unstyled mb-0">
                <li className="nav-item">
                  <NavLink className="nav-link" to="cancellationPolicy">
                    {footerData?.props?.cancellationPolicy
                      ? footerData?.props?.cancellationPolicy
                      : "CANCELLATION POLICY"}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="termsandconditions">
                    {footerData?.props?.termsAndConditions
                      ? footerData?.props?.termsAndConditions
                      : "TERMS AND CONDITIONS"}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="priavcypolicy">
                    {footerData?.props?.privacyPolicy
                      ? footerData?.props?.privacyPolicy
                      : "PRIVACY POLICY"}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              {/* <h5 className="mb-2 text-white footer-headings-title">
                SUBSCRIBE
              </h5> */}
              <h6 className="text-light subscribe__our__newsletter__text text-uppercase mb-0 pb-2">
                {footerData?.props?.newsLetterText
                  ? footerData?.props?.newsLetterText
                  : "SUBSCRIBE OUR NEWSLETTER"}
              </h6>
              <input
                type="email"
                value={emailValue}
                name="EMAIL"
                className="email form-control"
                id="mc-email"
                placeholder={
                  footerData?.props?.subscribeOurNewsletterInnerField ??
                  "ENTER EMAIL"
                }
                onChange={handleContactEmail}
                autoComplete="off"
              />
              <button
                className="btn book-btn mt-3 footer-contact"
                onClick={subscribeNewsLetter}
              >
                {footerData?.props?.sendButton
                  ? footerData?.props?.sendButton
                  : "SEND"}
              </button>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
