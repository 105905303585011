import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from "react-share";

const JournalDetails: React.FC = () => {
  const location = useLocation();

  const currentUrl = `${window.location.origin}${location.pathname}`;
  const [journalDetails, setJournalDetails] = useState<any>([]);
  const [blogs, setMoreBlogs] = useState<any>([]);
  const [categoryWiseBlogs, setCategoryWiseBlogs] = useState<any>([]);
  const [journalData, setNewJournalData] = useState<any>([]);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    getSpecialistBlogs();
  }, [id, journalDetails.author_uuid]);

  useEffect(() => {
    getJournalsByCategoryName();
  }, [
    blogs.length === 0,
    journalDetails.category && journalDetails.category[0].name,
  ]);

  useEffect(() => {
    getAllBlogs();
  }, []);

  useEffect(() => {
    getSingleBlogData();
  }, [id]);

  const getSingleBlogData = async () => {
    axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 1,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
              {
                field: "id",
                operator: "eq",
                value: id,
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "blogList",
            appName: "selfMaximized",
            folder: "blogs",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setJournalDetails(result?.data?.data[0]);
        } else {
          setJournalDetails([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getJournalsByCategoryName = async () => {
    if (journalDetails) {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          category: journalDetails.category
            ? journalDetails.category[0].name
            : "",
          blogId: id ? id : "",
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "blogListByCategory",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        })
        .then((result) => {
          const newData = result?.data?.data[0].blog_data;
          setCategoryWiseBlogs(newData);
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    }
  };
  const getSpecialistBlogs = async () => {
    if (journalDetails) {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          doctorUuid: journalDetails.author_uuid,
          blog_id: id,
          action: "command",
          command: [
            {
              agent: "getSpecialistBlog",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            setMoreBlogs(result?.data?.data?.response);
          } else {
            setMoreBlogs([]);
          }
        })
        .catch((err) => {
          return toast.error("Something went wrong", err);
        });
    }
  };

  const getAllBlogs = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 4,
            filters: [
              {
                field: "publish",
                operator: "eq",
                value: "1",
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "blogList",
            appName: "selfMaximized",
            folder: "blogs",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setNewJournalData(result?.data?.data);
        } else {
          setNewJournalData([]);
        }
      });
  };

  const checkBlogStatus = () => {
    const checkBlog =
      blogs.length === 0 && categoryWiseBlogs.length !== 0
        ? categoryWiseBlogs
        : blogs.length === 0 && categoryWiseBlogs.length === 0
        ? journalData
        : blogs;

    return checkBlog.length !== 0 && checkBlog !== null ? (
      checkBlog.map((elem: any) => (
        <Link
          to={`/journaldetails/${elem.id}`}
          state={elem}
          className="col-lg-6 position-relative px-2 px-md-0"
          key={elem.id}
        >
          <a href="#" className="journal-box">
            <div className="journal-thumb">
              <div className="cs-image">
                <img
                  src={elem.image}
                  alt="journal"
                  className="img-fluid w-100 main-img"
                  style={{ width: "auto", height: "520px" }}
                />
              </div>
            </div>
            <div className="journal-content">
              <p className="mb-0 title-of-the-journal py-1">
                <span className="d-block">{elem.title.toUpperCase()}</span>
              </p>
              <Link
                to={
                  elem?.otherAuthor === 0
                    ? `/buyproduct/${elem?.author ? elem?.author_uuid : 0}/0`
                    : `/categories/${
                        elem?.category && elem?.category[0]?.categoryid
                      }/${elem?.category && elem?.category[0]?.uuid}`
                }
              >
                <p className="journal-date-title mb-0 text-uppercase text-decoration-underline">
                  BY. {elem?.author ? elem?.author : "ADMIN"}
                </p>
              </Link>
            </div>
          </a>
        </Link>
      ))
    ) : (
      <span className="text-white letter_spacing text-center">
        NO MORE BLOGS AVAILABLE
      </span>
    );
  };

  const shareToThreads = (title: any, image: any) => {
    const text = title;
    const imageUrl = image;
    const threadsUrl = `threads://share?text=${encodeURIComponent(
      text
    )}&imageUrl=${encodeURIComponent(imageUrl)}`;

    window.open(threadsUrl);
  };

  return (
    <>
      <section
        className="custom-pt-1 custom-pb-2 parallaxie position-relative journal-detail-page-wrapper h-100 journal-details_banner"
        style={{
          backgroundImage: `url(${journalDetails.image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        data-overlay="6"
      >
        <div className="container-fluid position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="detail-page-banner-inner pt-5 pt-lg-0">
                <p className="mb-0 journal-detail-date-name text-uppercase">
                  {moment(journalDetails.onCreate).format("MMMM D, YYYY")}
                </p>
                <h1 className="international-day mb-0">
                  {journalDetails.title}
                </h1>

                <Link
                  to={
                    journalDetails?.otherAuthor === 0
                      ? `/buyproduct/${
                          journalDetails?.author
                            ? journalDetails?.author_uuid
                            : 0
                        }/0`
                      : `/categories/${
                          journalDetails?.category &&
                          journalDetails?.category[0]?.categoryid
                        }/${
                          journalDetails?.category &&
                          journalDetails?.category[0]?.uuid
                        }`
                  }
                >
                  <h3 className="text-white mb-0 text-uppercase cursor_pointer journal-author text-decoration-underline">
                    BY. {journalDetails.author}
                  </h3>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="page-content">
        <section className="the-journy-wrapper">
          <div className="container-fluid left-right-space">
            <div className="row">
              <div className="col-lg-12 pb-2">
                <p className="mb-0 the-journy py-2 text-uppercase">
                  {journalDetails.title}
                </p>
                <p className="mb-0 journy-description pb-5 text-uppercase">
                  {journalDetails.description}
                </p>
              </div>
              <div className="col-lg-12 pb-2">
                <div className="">
                  <div className="social-media-wrapper d-flex pt-3 position-relative">
                    <TwitterShareButton
                      url={currentUrl}
                      title={journalDetails.title}
                    >
                      <div
                        className="social-icons text-white"
                        style={{ marginTop: "2.5px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="30"
                          fill="none"
                          className="mb-1"
                          viewBox="0 0 512 512"
                          id="twitter"
                        >
                          <g clip-path="url(#a)">
                            <rect
                              width="512"
                              height="512"
                              fill="#000"
                              rx="60"
                            ></rect>
                            <path
                              fill="#fff"
                              d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="a">
                              <rect width="512" height="512" fill="#fff"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </TwitterShareButton>

                    <div className="social-icons one">
                      <FacebookShareButton
                        url={currentUrl}
                        hashtag="#YourHashtag"
                      >
                        <i>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="19px"
                            height="19px"
                          >
                            <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.462.099 2.794.142v3.24l-1.917.001c-1.503 0-1.794.714-1.794 1.761v2.31h3.588l-.467 3.622h-3.121V24h6.116C23.407 24 24 23.407 24 22.676V1.325C24 .593 23.407 0 22.675 0z" />
                          </svg>
                        </i>
                      </FacebookShareButton>
                    </div>

                    <div className="social-icons two">
                      <PinterestShareButton
                        url={currentUrl}
                        media={journalDetails.image}
                        description={journalDetails.description}
                      >
                        <i
                          className="fab fa-pinterest"
                          style={{ fontSize: "19.5px", marginTop: "8.4px" }}
                        ></i>
                      </PinterestShareButton>
                    </div>

                    <div className="social-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        width="26"
                        height="26"
                        viewBox="0 0 256 256"
                        xmlSpace="preserve"
                        onClick={() =>
                          shareToThreads(
                            journalDetails.title,
                            journalDetails.image
                          )
                        }
                      >
                        <defs></defs>
                        <g
                          style={{
                            stroke: "none",
                            strokeWidth: 0,
                            strokeDasharray: "none",
                            strokeLinecap: "butt",
                            strokeLinejoin: "miter",
                            strokeMiterlimit: 10,
                            fill: "none",
                            fillRule: "nonzero",
                            opacity: 1,
                          }}
                          transform="translate(1.41 1.41) scale(2.81 2.81)"
                        >
                          <path
                            d="M81.208 90H8.792C3.936 90 0 86.064 0 81.208V8.792C0 3.936 3.936 0 8.792 0h72.417C86.064 0 90 3.936 90 8.792v72.417C90 86.064 86.064 90 81.208 90z"
                            style={{
                              stroke: "none",
                              strokeWidth: 1,
                              strokeDasharray: "none",
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              fill: "rgb(0, 0, 0)",
                              fillRule: "nonzero",
                              opacity: 1,
                            }}
                            transform="matrix(1 0 0 1 0 0)"
                            strokeLinecap="round"
                          />
                          <path
                            d="M60.554 42.626c-.28-.134-.564-.263-.852-.387-.502-9.245-5.553-14.537-14.035-14.591-.038 0-.077 0-.115 0-5.073 0-9.293 2.166-11.89 6.106l4.665 3.2c1.94-2.943 4.985-3.571 7.227-3.571.026 0 .052 0 .078 0 2.793.018 4.9.83 6.264 2.413.993 1.153 1.656 2.746 1.985 4.756-2.476-.421-5.154-.55-8.017-.386-8.064.465-13.248 5.168-12.9 11.703.177 3.315 1.828 6.167 4.65 8.03 2.386 1.575 5.459 2.345 8.652 2.171 4.217-.231 7.526-1.84 9.834-4.783 1.753-2.234 2.862-5.13 3.351-8.778 2.01 1.213 3.5 2.809 4.322 4.728 1.399 3.262 1.48 8.622-2.893 12.992-3.832 3.828-8.438 5.484-15.399 5.535-7.722-.057-13.562-2.534-17.359-7.361-3.556-4.52-5.393-11.048-5.462-19.404.069-8.356 1.906-14.884 5.462-19.404 3.797-4.827 9.637-7.303 17.359-7.361 7.778.058 13.72 2.546 17.662 7.396 1.933 2.378 3.39 5.37 4.351 8.857l5.466-1.458c-1.165-4.293-2.997-7.992-5.491-11.06-5.054-6.218-12.446-9.404-21.97-9.47h-.038c-9.505.066-16.814 3.264-21.724 9.506-4.369 5.554-6.623 13.283-6.699 22.971v.023v.023c.076 9.688 2.33 17.417 6.699 22.971 4.91 6.242 12.219 9.44 21.724 9.506H45.5c8.45-.059 14.406-2.271 19.313-7.173 6.42-6.414 6.226-14.453 4.111-19.388C67.406 47.399 64.512 44.525 60.554 42.626zM45.964 56.344c-3.534.199-7.206-1.387-7.387-4.785-.134-2.519 1.793-5.331 7.604-5.666.666-.038 1.319-.057 1.96-.057 2.111 0 4.086.205 5.881.598C53.353 54.796 49.425 56.154 45.964 56.344z"
                            style={{
                              stroke: "none",
                              strokeWidth: 1,
                              strokeDasharray: "none",
                              strokeLinecap: "butt",
                              strokeLinejoin: "miter",
                              strokeMiterlimit: 10,
                              fill: "rgb(255, 255, 255)",
                              fillRule: "nonzero",
                              opacity: 1,
                            }}
                            transform="matrix(1 0 0 1 0 0)"
                            strokeLinecap="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="more-for-you py-0">
        <div className="container-fluid left-right-space">
          <div className="row pb-5">
            <div className="col-lg-12">
              <h2 className="mb-0 more-for-you-heading">MORE FOR YOU</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid overflow-hidden">
          <div className="row no-gutters portfolio_page_gallery">
            {checkBlogStatus()}
          </div>
        </div>
      </section>
    </>
  );
};

export default JournalDetails;
