import React from "react";
import { useCallback, useEffect, useState } from "react";
import Selfmaximized from "../../src/images/admin-logo.png";
import DummyProductImage from "../../../web/src/images/dummy/card.png";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../stripepayment/CheckoutForm";
import moment from "moment-timezone";

// const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

const Checkout: React.FC = () => {
  const { id } = useParams();
  const [clientSecret, setClientSecret] = useState<any>(null);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [cartList, setCartList] = useState<any>([]);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let data = localStorage.getItem("userInfo");
  let userInfo = null;
  if (typeof data === "string") {
    try {
      userInfo = JSON.parse(data);
    } catch (error) {
      console.error("Error parsing userInfo:", error);
    }
  } else {
    userInfo = data;
  }
  let lastActiveCartId = userInfo ? userInfo.lastActiveCart : null;
  let cartId: any = localStorage.getItem("cartId");
  const token = localStorage.getItem("token");
  const location = useLocation();
  const buyNowCartId = location?.state;

  const getCartList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          // "cartId":lastActiveCartId !== undefined && cartId ? cartId : cartId ? cartId :  lastActiveCartId,
          cartUuid:
            lastActiveCartId !== null && lastActiveCartId !== undefined
              ? lastActiveCartId
              : cartId,
          organizationId: 1,
          action: "command",
          command: [
            {
              agent: "cartList",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": token },
        }
      )
      .then((result) => {
        setCartList(result?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCartListCb = useCallback(getCartList, [cartId, lastActiveCartId]);

  useEffect(() => {
    if (!buyNowCartId) {
      getCartListCb();
    }
  }, [buyNowCartId, getCartListCb]);

  // useEffect(() => {
  //     if (!cartId || !buyNowCartId) {
  //         navigate('/');
  //     }
  // }, [!cartId, !buyNowCartId])

  const getBuyNowCartList = async () => {
    if (buyNowCartId !== null) {
      await axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            cartUuid:
              lastActiveCartId !== null && lastActiveCartId !== undefined
                ? lastActiveCartId
                : cartId,
            organizationId: 1,
            action: "command",
            command: [
              {
                agent: "cartList",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          },
          {
            headers: { "x-access-token": token },
          }
        )
        .then((result) => {
          setCartList(result?.data?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getBuyNowCartListCb = useCallback(getBuyNowCartList, [cartId]);

  useEffect(() => {
    getBuyNowCartListCb();
  }, [getBuyNowCartListCb]);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            orderId: id,
            // cartUuid:
            //   buyNowCartId !== null
            //     ? buyNowCartId
            //     : lastActiveCartId !== undefined && cartId
            //     ? cartId
            //     : cartId
            //     ? cartId
            //     : lastActiveCartId,
            cartUuid:
              lastActiveCartId !== null && lastActiveCartId !== undefined
                ? lastActiveCartId
                : cartId,
            organizationId: 1,
            action: "command",
            command: [
              {
                agent: "createCheckout",
                appName: "selfMaximized",
                folder: "payment",
              },
            ],
          },
          {
            headers: { "x-access-token": localStorage.getItem("token") },
          }
        );

        if (result?.data?.code === 1) {
          setClientSecret(result?.data?.data?.clientSecret);
        } else {
          console.error("Failed to fetch clientSecret:", result);
        }
      } catch (error) {
        console.error("Error fetching clientSecret:", error);
      }
    };
    fetchClientSecret();
  }, [id, buyNowCartId]);

  useEffect(() => {
    const initializeStripe = async () => {
      const stripeKey = process.env.REACT_APP_STRIPE_KEY;
      if (stripeKey) {
        const stripe = await loadStripe(stripeKey);
        setStripePromise(() => stripe);
      }
    };

    initializeStripe();
  }, []);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#000000",
      colorBackground: "#000000",
      colorText: "#ffffff",
    },
  };
  const options: any = {
    clientSecret,
    appearance,
  };
  function getTimeDifference(startTime: any, endTime: any) {
    // Parse the start and end times
    const [startHour, startMinute, startPeriod] = startTime.split(/[:\s]/);
    const [endHour, endMinute, endPeriod] = endTime.split(/[:\s]/);

    // Convert to 24-hour format
    const start24 =
      (parseInt(startHour) % 12) + (startPeriod === "PM" ? 12 : 0);
    const end24 = (parseInt(endHour) % 12) + (endPeriod === "PM" ? 12 : 0);

    // Calculate minutes for each time
    const startInMinutes = start24 * 60 + parseInt(startMinute);
    const endInMinutes = end24 * 60 + parseInt(endMinute);

    // Return the difference in minutes
    return endInMinutes - startInMinutes;
  }

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <div className="page-content pt-5 mt-5 ">
            <section className="cart__page__wrapper py-0 cart__product__quantity__total pt-0 pt-lg-5">
              <div className="container-fluid left-right-space">
                <div className="row">
                  <div className="col-lg-8 main">
                    <div className="">
                      {cartList.length !== 0 && cartList !== null ? (
                        cartList.map((elem: any) => (
                          <div
                            className="row d-flex align-items-center pb-5"
                            key={elem.id}
                          >
                            <div className="col-4">
                              <div className="card__product__image">
                                <img
                                  src={
                                    elem.specialist_profile === null
                                      ? elem.image
                                      : elem.specialist_profile
                                  }
                                  alt=""
                                  className="img-fluid w-100 card__product__image--image"
                                  style={{ width: "auto", height: "auto" }}
                                  loading="lazy"
                                />
                              </div>
                            </div>
                            <div className="col-8">
                              {/* {elem.packageType === "Package" ? (
                                <div>
                                  <h3 className="mb-0 buy__product__title">
                                    {elem.packageType.toUpperCase()} |{" "}
                                    {elem.title.toUpperCase()}
                                  </h3>
                                  <p className="mb-0 buy__product__description pb-2">
                                    {" "}
                                    {elem.packageDuration} HR VIRTUAL
                                    APPOINTMENT
                                  </p>
                                </div>
                              ) : ( */}
                              <div>
                                <h3 className="mb-0 buy__product__title text-white">
                                  DR.{elem?.specialist_name?.toUpperCase()}
                                </h3>
                                {elem?.book_status === 1 ? (
                                  <>
                                    <p className="mb-0 buy__product__description pb-2">
                                      {elem?.appnt_type?.toUpperCase()} |
                                      {elem?.appointment_day?.toUpperCase()} |
                                      {elem?.appointment_date?.toUpperCase()}
                                    </p>
                                    <p className="text-white mb-0 pb-0 custom-letter-spacing text-uppercase">
                                      SELECTED SLOT -{" "}
                                      {userTimezone &&
                                        moment
                                          .tz(
                                            elem.start_time,
                                            "hh:mm A",
                                            userTimezone
                                          )
                                          .utc()
                                          .format("HH:mm A")}{" "}
                                      TO{" "}
                                      {userTimezone &&
                                        moment
                                          .tz(
                                            elem.end_time,
                                            "hh:mm A",
                                            userTimezone
                                          )
                                          .utc()
                                          .format("HH:mm A")}{" "}
                                      |{" "}
                                      {getTimeDifference(
                                        elem.start_time,
                                        elem.end_time
                                      )}{" "}
                                      MIN{" "}
                                    </p>
                                  </>
                                ) : (
                                  <span className="text-white letter_spacing">
                                    NOTE - YOU WOULD BE ABLE TO SELECT SLOT
                                    LATER FROM YOUR ACCOUNT
                                  </span>
                                )}

                                <p className="text-white mb-0 pb-0 custom-letter-spacing text-uppercase">
                                  MODE - {elem?.appnt_mode}
                                </p>
                              </div>
                              {/* )} */}
                              <div className="col-lg-7">
                                <p className="amount mb-0 text-center add__cart__amount abount-box-main text-white">
                                  <span className="dollar__symbol text-white">
                                    $
                                  </span>
                                  <span className="amt ps-2 text-white">
                                    {elem.price}
                                    {` (Tax :${elem.tax.slice(
                                      0,
                                      -3
                                    )}%) - `}{" "}
                                    <span className="dollar__symbol text-white">
                                      ${" "}
                                    </span>
                                    {`${elem.total}`}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <span>Cart list is empty</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4 sidebar">
                    <div className=" p-2">
                      <CheckoutForm buyNowCartId={cartId} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Elements>
      )}
    </>
  );
};
export default Checkout;
