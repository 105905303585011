import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./Layout/Dashboard";
import Journal from "./Layout/Journal";
import Specialist from "./Layout/Specialist";
import Packages from "./Layout/Packages";
import SpecialistRegistrationspecialistdetails from "./Layout/SpecialistRegistration";
import Book from "./Layout/Book";
import UserRegistration from "./Layout/UserRegistration";
import Cart from "./Layout/Cart";
import JournalDetails from "./Layout/JournalDetails";
import AllBlog from "./Layout/AllBlog";
import SingleBlogDetails from "./Layout/SingleBlogDetails";
import About from "./Layout/About";
import BuyProduct from "./Layout/BuyProduct";
import BookSpecialistDetails from "./pages/Dashboard/Component/BookSpecialistDetails";
import Checkout from "./Layout/Checkout";
import SuccessPage from "./pages/Dashboard/Component/SuccessPayment";
import ContactUs from "./Layout/ContactUs";
import { PrivacyPolicy } from "./Layout/PrivacyPolicy";
import Faq from "./Layout/Faq";
import TermsAndCondition from "./Layout/TermsAndCondition";
import CancellationPolicy from "./Layout/CancellationPolicy";
import Careers from "./Layout/Careers";
import NavBarShimmerUI from "./Layout/NavBarShimmerUI";
import withTitle from "./Layout/HOC/WithTitle";
import axios from "axios";
import { useEffect, useState } from "react";

const FaqWithDynamicTitle = withTitle(Faq);

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "/",
        element: <Book />,
      },
      {
        path: "/userregistration",
        element: <UserRegistration />,
      },
      {
        path: "/specialistdetails",
        element: <SpecialistRegistrationspecialistdetails />,
      },
      {
        path: "/navbarshimmerui",
        element: <NavBarShimmerUI />,
      },
      {
        path: "/journal",
        element: <Journal />,
      },
      {
        path: "/journaldetails/:id",
        element: <JournalDetails />,
      },
      {
        path: "/categories",
        element: <AllBlog />,
      },
      {
        path: "/categories/:categoryid/:id",
        element: <SingleBlogDetails />,
      },
      {
        path: "/specialist",
        element: <Specialist />,
      },
      {
        path: "/packages",
        element: <Packages />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/priavcypolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/faq",
        element: <FaqWithDynamicTitle title="FREQUENTLY ASKED QUESTIONS" />,
      },
      {
        path: "/career",
        element: <Careers />,
      },
      {
        path: "/termsandconditions",
        element: <TermsAndCondition />,
      },
      {
        path: "/cancellationPolicy",
        element: <CancellationPolicy />,
      },

      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "bookproduct",
        element: <BookSpecialistDetails />,
      },
      {
        path: "/buyproduct/:id/:categoryid",
        element: <BuyProduct />,
      },

      {
        path: "/checkout/:id",
        element: <Checkout />,
      },
      {
        path: "/success/:id",
        element: <SuccessPage />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={appRouter} />
    </>
  );
}
export default App;
